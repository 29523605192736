<template>
  <v-card
    id="user-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in computedTabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ t(`facilities.${tab.title}`) }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <facility-tab-general
          v-if="loaded"
          :data-params="facilityData"
          :tab="tab"
          :option="option"
          :sports="sportsOptions"
          :groups="groupsOptions"
          :timezones="timezonesOptions"
          :time-formats="timeFormatOptions"
          @updateAvatar="updateAvatar"
          @updateEditAvatar="onUpdateEditAvatar"
          @save="save"
          @changeTab="changeTab"
          @cancelAction="cancelAction"
        ></facility-tab-general>
      </v-tab-item>

      <v-tab-item>
        <facility-tab-images
          v-if="loaded"
          :data-params="facilityData"
          :tab="tab"
          :option="option"
          @save="save"
          @changeTab="changeTab"
          @cancelAction="cancelAction"
        ></facility-tab-images>
      </v-tab-item>

      <v-tab-item>
        <facility-tab-location
          v-if="loaded"
          :data-params="facilityData"
          :tab="tab"
          :option="option"
          :countries="countries"
          :states="states"
          @save="save"
          @changeTab="changeTab"
          @cancelAction="cancelAction"
        ></facility-tab-location>
      </v-tab-item>

      <v-tab-item>
        <facility-tab-schedule
          v-if="loaded"
          :data-params="facilityData"
          :tab="tab"
          :option="option"
          @save="save"
          @changeTab="changeTab"
          @cancelAction="cancelAction"
        ></facility-tab-schedule>
      </v-tab-item>

      <v-tab-item>
        <facility-tab-bank-account
          v-if="loaded"
          :id="dataId"
          :data-params="facilityData"
          :tab="tab"
          :option="option"
          @save="save"
          @changeTab="changeTab"
          @cancelAction="cancelAction"
        ></facility-tab-bank-account>
      </v-tab-item>

      <v-tab-item v-if="hasPermission(152)">
        <facility-tab-fanaty
          v-if="loaded"
          :id="dataId"
          :data-params="facilityData"
          :tab="tab"
          :option="option"
          @save="save"
          @changeTab="changeTab"
          @cancelAction="cancelAction"
        ></facility-tab-fanaty>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
  mdiImageMultipleOutline,
  mdiBank,
  mdiSoccer,
} from '@mdi/js'
import { onMounted, ref, computed, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter, toCapitalize } from '@core/utils'
import { COUNTRIES_JSON_URL, STATES_JSON_URL } from '@core/utils/constans'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import { error, success, info } from '@core/utils/toasted'
import {
  createFacility,
  editFacility,
  editFacilityLogo,
  editFacilityImage,
  getSports,
  getGroups,
  getFacilityTimezones,
  getFacilityById,
} from '@api'
import usePermissions from '@core/utils/usePermissions'
import FacilityTabGeneral from './FacilityTabGeneral.vue'
import FacilityTabLocation from './FacilityTabLocation.vue'
import FacilityTabSchedule from './FacilityTabSchedule.vue'
import FacilityTabImages from './FacilityTabImages.vue'
import FacilityTabBankAccount from './FacilityTabBankAccount.vue'
import FacilityTabFanaty from './FacilityTabFanaty.vue'

export default {
  components: {
    FacilityTabGeneral,
    FacilityTabLocation,
    FacilityTabSchedule,
    FacilityTabImages,
    FacilityTabBankAccount,
    FacilityTabFanaty,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const {
      configOrganization,
      configFacility,
      refreshFacilitiesByOrganization,
    } = useSelectOptions()
    const { hasPermission } = usePermissions()

    const tab = ref(0)
    const option = ref(1)
    const pageData = ref()
    const filterData = ref()
    const dataId = ref(null)
    const loaded = ref(false)

    const facilityData = ref({
      name: null,
      short_address: null,
      signer_name: null,
      email: null,
      phone: null,
      logo: null,
      status: 'A',
      full_address: null,
      schedule: [],
      sports_ids: [],
      tax_percent: 7,
      groups_ids: [],
      owner_group_id: null,
      group_name: null,
      group_email: null,
      group_phone: null,
      group_billing_cycle: null,
      group_comission: 0,
      group_min_orders_amount: null,
      group_min_orders_filter: 'G',
      fanaty_service_fee: null,
      fanaty_service_fee_percent: null,
      query_place_id: null,
      order_phone_number: 'N',
      video_declaration: 'N',
      description: null,
      timezone: null,
      time_format: null,
      city_code: null,
      city_name: null,
      state_code: null,
      state_name: null,
      country_code: null,
      country_name: null,
      account_bank_name: null,
      account_number: null,
      account_holder_name: null,
      account_routing_number: null,
      sms_language: 'en',
      cancellation_hours_qty: null,
    })
    const imageLogo = ref(null)
    const imagesDescription = ref([])

    const countries = ref([])
    const states = ref([])

    const sportsOptions = ref([])
    const groupsOptions = ref([])
    const timezonesOptions = ref([])
    const timeFormatOptions = computed(() => [
      { text: `12 ${toCapitalize(t('groups.hours'))}`, value: '12 hours' },
      { text: `24 ${toCapitalize(t('groups.hours'))}`, value: '24 hours' },
    ])

    const postData = ref({
      name: null,
      short_address: null,
      signer_name: null,
      email: null,
      phone: null,
      logo: null,
      status: 'A',
      full_address: null,
      latitude: 0,
      longitude: 0,
      schedule: [],
      sports_ids: [],
      tax_percent: null,
      groups_ids: [],
      owner_group_id: null,
      group_name: null,
      group_email: null,
      group_phone: null,
      group_billing_cycle: null,
      group_comission: 0,
      group_min_orders_amount: null,
      group_min_orders_filter: 'G',
      fanaty_service_fee: null,
      fanaty_service_fee_percent: null,
      query_place_id: null,
      order_phone_number: 'N',
      video_declaration: 'N',
      description: null,
      timezone: null,
      time_format: null,
      city_code: null,
      city_name: null,
      state_code: null,
      state_name: null,
      country_code: null,
      country_name: null,
      account_bank_name: null,
      account_number: null,
      account_holder_name: null,
      account_routing_number: null,
      sms_language: 'en',
      cancellation_hours_qty: null,
    })

    const putData = ref({
      name: null,
      short_address: null,
      signer_name: null,
      email: null,
      phone: null,
      full_address: null,
      latitude: 0,
      longitude: 0,
      schedule: [],
      sports_ids: [],
      tax_percent: null,
      groups_ids: [],
      owner_group_id: null,
      group_name: null,
      group_email: null,
      group_phone: null,
      group_billing_cycle: null,
      group_comission: 0,
      group_min_orders_amount: null,
      group_min_orders_filter: 'G',
      fanaty_service_fee: null,
      fanaty_service_fee_percent: null,
      query_place_id: null,
      order_phone_number: 'N',
      video_declaration: 'N',
      description: null,
      timezone: null,
      time_format: null,
      city_code: null,
      city_name: null,
      state_code: null,
      state_name: null,
      country_code: null,
      country_name: null,
      account_bank_name: null,
      account_number: null,
      account_holder_name: null,
      account_routing_number: null,
      sms_language: 'en',
      cancellation_hours_qty: null,
    })

    const tabs = ref([
      {
        title: 'general',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
        show: true,
      },
      {
        title: 'images',
        icon: mdiImageMultipleOutline,
        disabled: true,
        hide: false,
        show: true,
      },
      {
        title: 'location',
        icon: mdiLockOpenOutline,
        disabled: true,
        hide: false,
        show: true,
      },
      {
        title: 'schedule',
        icon: mdiInformationOutline,
        disabled: true,
        hide: false,
        show: true,
      },
      {
        title: 'bank_account',
        icon: mdiBank,
        disabled: true,
        hide: false,
        show: true,
      },
      {
        title: 'fanaty',
        icon: mdiSoccer,
        disabled: true,
        hide: false,
        show: hasPermission(152),
      },
    ])
    const computedTabs = computed(() => tabs.value.filter(e => e.show))

    const onUpdateEditAvatar = async data => {
      imageLogo.value = data.imageData
    }

    const changeTab = (tabNumber, validForm, data) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
            imageLogo.value = data.imageData
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
            imagesDescription.value = data.imagesDescription
          }
          break
        case 3:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[3].disabled = false
          }
          break
        case 4:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[4].disabled = false
          }
          break
        case 5:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[5].disabled = false
          }
          break
        default:
          break
      }
      tab.value = tabNumber
    }

    const updateAvatar = async image => {
      const formData = new FormData()
      if (image && dataId.value) {
        formData.append('file', image)
        const responseLogo = await editFacilityLogo(formData, dataId.value)

        if (responseLogo.ok) {
          success(responseLogo.message)
        }
      }
    }

    const updateImage = async image => {
      const formData = new FormData()
      if (image && dataId.value) {
        formData.append('file', image)
        const responseLogo = await editFacilityImage(formData, dataId.value)

        if (responseLogo.ok) {
          success(responseLogo.message)
        }
      }
    }

    const save = async data => {
      let resp = null
      if (option.value === 1) {
        if (!imageLogo.value) {
          info('Seleccione una imagen!')

          return
        }
        if (data && imageLogo.value) {
          postData.value = Object.assign(postData.value, {
            name: data.name,
            short_address: data.short_address,
            signer_name: data.signer_name,
            email: data.email,
            phone: data.phone,
            logo: data.logo ? data.logo : null,
            status: data.status,
            full_address: data.full_address ? data.full_address : null,
            latitude: data.latitude ? data.latitude : 0,
            longitude: data.longitude ? data.longitude : 0,
            schedule: data.schedule,
            sports_ids: data.sports_ids,
            tax_percent: data.tax_percent,
            groups_ids: data.groups_ids,
            owner_group_id: data.owner_group_id,
            group_name: data.group_name,
            group_email: data.group_email,
            group_phone: data.group_phone,
            group_billing_cycle: data.group_billing_cycle,
            group_comission: data.group_comission,
            group_min_orders_amount: data.group_min_orders_amount,
            group_min_orders_filter: data.group_min_orders_filter,
            fanaty_service_fee: data.fanaty_service_fee,
            fanaty_service_fee_percent: data.fanaty_service_fee_percent,
            query_place_id: data.query_place_id,
            order_phone_number: data.order_phone_number,
            video_declaration: data.video_declaration,
            description: data.description,
            timezone: data.timezone,
            time_format: data.time_format,
            city_code: data.city_code,
            city_name: data.city_name,
            state_code: data.state_code,
            state_name: data.state_name,
            country_code: data.country_code,
            country_name: data.country_name,
            account_bank_name: data.account_bank_name,
            account_number: data.account_number,
            account_holder_name: data.account_holder_name,
            account_routing_number: data.account_routing_number,
            sms_language: data.sms_language,
            cancellation_hours_qty: data.cancellation_hours_qty,
          })

          resp = await createFacility(postData.value)

          if (resp.ok) {
            dataId.value = resp.data.id
            await updateAvatar(imageLogo.value)

            if (imagesDescription.value && imagesDescription.value.length) {
              imagesDescription.value.forEach(async e => {
                await updateImage(e.data)
              })
            }
          }
        }
      }

      if (option.value === 3) {
        const notValid = !data.phone
          || !data.sports_ids.length
          || !data.groups_ids.length
          || !data.owner_group_id
          || !data.name
          || !data.short_address
          || !data.timezone
          || !data.time_format
          || !data.sms_language
          || !data.signer_name
          || !data.email
          || Number.isNaN(data.cancellation_hours_qty)
          || Number.isNaN(data.tax_percent)
          || !data.status

        if (notValid) {
          tab.value = 0

          return
        }

        putData.value = Object.assign(putData.value, {
          name: data.name,
          short_address: data.short_address,
          signer_name: data.signer_name,
          email: data.email,
          phone: data.phone,
          status: data.status,
          full_address: data.full_address ? data.full_address : null,
          latitude: data.latitude ? data.latitude : 0,
          longitude: data.longitude ? data.longitude : 0,
          schedule: data.schedule,
          sports_ids: data.sports_ids,
          tax_percent: data.tax_percent,
          groups_ids: data.groups_ids,
          owner_group_id: data.owner_group_id,
          group_name: data.group_name,
          group_email: data.group_email,
          group_phone: data.group_phone,
          group_billing_cycle: data.group_billing_cycle,
          group_comission: data.group_comission,
          group_min_orders_amount: data.group_min_orders_amount,
          group_min_orders_filter: data.group_min_orders_filter,
          fanaty_service_fee: data.fanaty_service_fee,
          fanaty_service_fee_percent: data.fanaty_service_fee_percent,
          query_place_id: data.query_place_id,
          order_phone_number: data.order_phone_number,
          video_declaration: data.video_declaration,
          description: data.description,
          timezone: data.timezone,
          time_format: data.time_format,
          city_code: data.city_code,
          city_name: data.city_name,
          state_code: data.state_code,
          state_name: data.state_name,
          country_code: data.country_code,
          country_name: data.country_name,
          account_bank_name: data.account_bank_name,
          account_number: data.account_number,
          account_holder_name: data.account_holder_name,
          account_routing_number: data.account_routing_number,
          sms_language: data.sms_language,
          cancellation_hours_qty: data.cancellation_hours_qty,
        })

        resp = await editFacility(putData.value, dataId.value)

        if (imageLogo.value) {
          await updateAvatar(imageLogo.value)
        }

        if (imagesDescription.value && imagesDescription.value.length) {
          imagesDescription.value.forEach(async e => {
            await updateImage(e.data)
          })
        }

        await refreshFacilitiesByOrganization()
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const goBack = () => {
      router.replace({
        name: 'views-facility-settings-view',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      tabs.value[3].disabled = true
      facilityData.value = {}
      facilityData.value.sms_language = 'en'
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option || 2
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      dataId.value = route.value.params.id

      if (dataId.value) {
        const resp = await getFacilityById(dataId.value)
        if (resp.ok) {
          facilityData.value = resp.data
        } else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }

      const response = await getSports()
      sportsOptions.value = response.data

      const respTimezones = await getFacilityTimezones()
      timezonesOptions.value = respTimezones.data

      const responseGroups = await getGroups()
      groupsOptions.value = responseGroups.data

      const loadingIndicator = document.getElementsByClassName('loading-screen')[0]
      loadingIndicator.style.display = 'flex'
      try {
        await fetch(COUNTRIES_JSON_URL)
          .then(ipResponse => ipResponse.json())
          .then(async data => {
            countries.value = data
          })

        await fetch(STATES_JSON_URL)
          .then(ipResponse => ipResponse.json())
          .then(async data => {
            states.value = data
          })
      } catch (e) {
        console.error(e)
      } finally {
        loadingIndicator.style.display = 'none'
      }
      loaded.value = true
    })

    return {
      tab,
      tabs,
      computedTabs,
      option,
      dataId,
      loaded,
      facilityData,
      imageLogo,
      imagesDescription,
      postData,
      putData,

      countries,
      states,
      sportsOptions,
      groupsOptions,
      timezonesOptions,
      timeFormatOptions,

      changeTab,
      save,
      cancelAction,
      updateAvatar,
      onUpdateEditAvatar,
      updateImage,
      goBack,
      hasPermission,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
        mdiImageMultipleOutline,
        mdiBank,
      },
    }
  },
}
</script>
