<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text
      class="px-md-16 px-lg-16"
    >
      <!-- <v-row class="d-flex align-end mb-3">
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="openTime"
            :label="t('fields.time_open')"
            :items="timesOptions"
            item-text="text"
            item-value="value"
            dense
            outlined
            clearable
            hide-details
            :prepend-inner-icon="icons.mdiClockTimeFourOutline"
            :append-icon="icons.mdiMenuDown"
            :readonly="option===2"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="closeTime"
            :label="t('fields.time_close')"
            :items="timesOptions"
            item-text="text"
            item-value="value"
            dense
            outlined
            clearable
            hide-details
            :prepend-inner-icon="icons.mdiClockTimeFourOutline"
            :append-icon="icons.mdiMenuDown"
            :readonly="option===2"
          />
        </v-col>

        <v-col
          cols="12"
          md="2"
          class="text-center"
        >
          <v-btn
            color="secondary"
            block
            @click="setDaySelected"
          >
            {{ t('fields.set_time') }}
          </v-btn>
        </v-col>
      </v-row> -->

      <!-- table -->
      <v-data-table
        v-model="daySelected"
        :headers="computedTableColumns"
        :items="option===1 ? scheduleInit : dataComputed.schedule"
        item-key="weekday"
        dense
        hide-default-footer
      >
        <!-- weekday -->
        <template #[`item.weekday`]="{item}">
          {{ t(`weekdays.${item.weekday.toLowerCase()}`) }}
        </template>
        <!--open_time -->
        <template #[`item.open_time`]="{item}">
          <v-autocomplete
            v-model="item.open_time"
            :items="computedMinutes"
            item-text="value"
            item-value="hour24"
            class="py-2"
            style="max-width: 250px;"
            dense
            outlined
            hide-details
            :append-icon="icons.mdiMenuDown"
            :readonly="option===2"
          />
        </template>

        <!-- close_time -->
        <template #[`item.close_time`]="{item}">
          <v-autocomplete
            v-model="item.close_time"
            :items="computedMinutes"
            item-text="value"
            item-value="hour24"
            class="py-2"
            style="max-width: 250px;"
            dense
            outlined
            hide-details
            :append-icon="icons.mdiMenuDown"
            :readonly="option===2"
          />
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on: tooltip, attrs }">
              <v-btn
                color="secondary"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onClear(item)"
              >
                <v-icon
                  size="20"
                  class="me-2"
                  color="white"
                >
                  {{ icons.mdiClose }}
                </v-icon>
                {{ t('tooltip.closed') }}
              </v-btn>
            </template>
            <span>{{ t('tooltip.closed') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- action buttons -->
    <options-buttom-tab
      :tab-number="tab"
      :is-mode-save-edit="option !== 2"
      :info-required="true"
      :no-cancel="option === 3"
      :no-arrow="option === 2"
      :no-save="true"
      @onCancel="onCancelAction"
      @onNextTab="changeTab"
    >
    </options-buttom-tab>
  </v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { mdiCalendar, mdiClockTimeFourOutline, mdiClose } from '@mdi/js'
import { info } from '@core/utils/toasted'
import { HOURS_MILITARY_OPTIONS, sumMinutesOptions } from '@core/utils/reservation'
import { useUtils } from '@core/libs/i18n'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const { t } = useUtils()
    const openTime = ref(null)
    const closeTime = ref(null)

    const modalOpen = ref(false)
    const modalClose = ref(false)

    const timesOptions = ref(HOURS_MILITARY_OPTIONS)
    const computedMinutes = computed(() => sumMinutesOptions('00:00', '23:59'))
    const daySelected = ref([])
    const computedTableColumns = computed(() => [
      { text: t('fields.weekday').toUpperCase(), value: 'weekday', sortable: false },
      { text: t('fields.open').toUpperCase(), value: 'open_time', sortable: false },
      { text: t('fields.close').toUpperCase(), value: 'close_time', sortable: false },
      {
        text: t('tooltip.actions').toUpperCase(),
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ])

    const tableColumns = ref([
      { text: 'WEEKDAY', value: 'weekday', sortable: false },
      { text: 'OPEN', value: 'open_time', sortable: false },
      { text: 'CLOSE', value: 'close_time', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ])

    const scheduleInit = ref([
      {
        weekday: 'Monday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Tuesday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Wednesday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Thursday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Friday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Saturday',
        open_time: null,
        close_time: null,
      },
      {
        weekday: 'Sunday',
        open_time: null,
        close_time: null,
      },
    ])

    const dataComputed = computed(() => props.dataParams)
    const scheduleComputed = computed(() => props.dataParams.schedule)

    const setDaySelected = () => {
      if ((openTime.value || closeTime.value) && daySelected.value.length) {
        if (props.option === 1) {
          scheduleInit.value.forEach(e => {
            if (daySelected.value.includes(e)) {
              e.open_time = openTime.value
              e.close_time = closeTime.value
            }
          })
        } else {
          dataComputed.value.schedule.forEach(e => {
            if (daySelected.value.includes(e)) {
              e.open_time = openTime.value
              e.close_time = closeTime.value
            }
          })
        }
      } else info('Seleccione el dia del horario!')
    }

    const onCancelAction = () => {
      form.value.reset()
      emit('cancelAction')
    }

    const onClear = item => {
      /* eslint-disable no-param-reassign */
      item.open_time = null
      item.close_time = null
    }

    const validate = arraySchedule => arraySchedule.some(e => e.open_time !== null || e.close_time !== null)

    const changeTab = tab => {
      if (props.option === 1 ? validate(scheduleInit.value) : validate(dataComputed.value.schedule)) {
        if (props.option === 1) dataComputed.value.schedule = scheduleInit.value

        emit('changeTab', tab, true, dataComputed.value)
      } else {
        info('Seleccione el horario!')
      }
    }

    const onSaveAction = () => {
      if (props.option === 1 ? validate(scheduleInit.value) : validate(dataComputed.value.schedule)) {
        if (props.option === 1) dataComputed.value.schedule = scheduleInit.value

        emit('save', dataComputed.value)
      } else {
        info('Seleccione el horario!')
      }
    }

    return {
      // Data
      form,
      tableColumns,
      computedTableColumns,
      scheduleInit,
      openTime,
      closeTime,
      modalOpen,
      modalClose,
      daySelected,
      timesOptions,
      computedMinutes,

      // Computed
      dataComputed,
      scheduleComputed,

      // Validations
      required,

      // Methods
      onCancelAction,
      onSaveAction,
      changeTab,
      setDaySelected,
      onClear,

      // i18n
      t,

      // Icons
      icons: {
        mdiCalendar,
        mdiClockTimeFourOutline,
        mdiClose,
      },
    }
  },
}
</script>
